@import "../index.css";

.icons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5vh; 
}

i {
    margin-left: 3vw !important;
}
.contact-figure {
    text-align: center;
    float: right;
    margin-left: 10vw;

}
.contact-caption {
    font-size: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 900px){
    .icons-container {
        justify-content: left;
    }
    
    .contact-caption {
    max-width: 30vw;
    }
}