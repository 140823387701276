@import "../index.css";

.black {
    background-color: black;
    width: 100%;
    margin-top: 20vh;
}

.speech4children {
    color: white;
    font-size: 35px;
    padding-top: 3vh;
    width: 70%;
}

.contact-us {
    position:relative;
    top:-10vh !important;
    color: white;
    font-size: 15px;
    line-height:1.75em;
    margin-left: 70%;
}


@media only screen and (min-width: 700px) and (max-width: 1205px){
    .black {
      height: 20vh;
      margin-top: 5vh;
      width: 100%;
    }
    .contact-us {
        top:-10vh !important;
    }
  }


@media only screen and (min-width: 300px) and (max-width: 699px){
    .contact-us {
        margin-top:15vh;
        margin-left: 10%;
        width: 70%;
    }
  }