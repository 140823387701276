@import "../index.css";

p {
    min-height: 60vh;
    line-height: 2.0em;
    font-size: 1.2em;
}

.picture-container{
    display:flex;
    flex-direction:row;
    justify-content: center;
    flex-wrap:wrap;
}

.about-picture {
    margin-right: 2vw;
}

.about-description {
    margin-left: 10%;
    margin-right: 10%;
}
@media only screen and (min-width: 300px) and (max-width: 800px){
    .about-picture {
        margin-bottom: 2vh !important;
    }
}
