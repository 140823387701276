body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Josefin Sans';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border {
  border-bottom: 2px solid black;
  margin: 0.75vw;
  width: 50%;
  margin-left:auto;
  margin-right:auto;
}

.page-title{
    text-align: center;
}

.page-title-caption{
  font-size: 22px;
}
.wrapper {
  padding-left: 5vw;
  padding-right: 5vw;
}