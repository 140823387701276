@import "../index.css";

.office-pictures {
    height: auto;
    width: 100vh !important;
}

.arrow-buttons {
    text-align:center;
}
