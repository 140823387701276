@import "../index.css";

.gray {
    width: 100%;
    background-color: lightgray;
    height: 40vh;
    margin-top: 15vw;
}

.testimonial-quote {
    font-size: 20px;
    padding-top: 5vh;
    padding-left: 5%;
    padding-right: 5%;
    line-height: 40px;
    height: auto;
}

.button-container {
    display: flex;
    flex-direction: row;
    margin-left:auto;
    margin-right:auto;
    width: 300px;
}

.dot-buttons{
    background-color: white !important;
     padding: 1vw !important;
    margin: 0.5vw !important;
    z-index:100;
}

.dot-buttons:hover {
    background-color: purple !important;
}

.dot-buttons:focus {
    background-color: purple !important;
}

.active-button{
    background-color: purple !important;
    padding: 1vw !important;
    margin: 0.5vw !important;
    z-index:100;
}
.services-home-title {
    padding-top: 2vh;
    padding-left: 5vw;
}

.services-home-text {
    font-size: 1.2em;
    padding-left: 5vw;
    width: 70%;
}

.services-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: center;
    width: 100%;
    padding-left: 3vw;
    padding-right: 3vw;
}

.brain-picture {
    width: 12vw; 
    height: auto;
    margin-left: 3vw;
    margin-right: 3vw;
}

figure figcaption {
    margin-left: 3vw;
    text-align: center;
    max-width: 10vw;
    font-size: 15px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
}

.know-more {
    font-size: 30px;
    text-align:center;
}

@media only screen and (min-width: 1700px) and (max-width: 2500px){
    .gray {
        margin-top: 22vw;
    }

}
@media only screen and (min-width: 699px) and (max-width: 880px){
    .button-container {
       width: 200px;
    }
}

  @media only screen and (min-width: 300px) and (max-width: 699px){
    .gray {
      height: 300px;
      margin-top: 5vh;
    }
    .button-container {
        padding-top: 0vh;
        width: 100px;
    }
    .testimonial-quote {
        font-size: 18px;
        line-height: 30px;
    }
    .brain-picture {
        width: 60%;
    }
    figure figcaption {
        width: 20%;
        margin-left: 5vh;
    }
    .know-more {
        font-size: 25px;
    }
    .services-home-title {
        padding-left: 5vh;
    }
    
    .services-home-text {
        padding-left: 5vh;
        width: 80%;
    }
  }