@import "../index.css";

.menu-container {
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
}

.menu-item {
    z-index: 100;
    width: 100%;
    color: black;
    font-family: 'Josefin Sans';
    font-weight: 'bold';
    font-size: 18px;
    margin-top: 1vw;
}


.menu-item:hover {
    font-weight: 'bolder';
    color: purple;
}

.contact-button {
    z-index: 100;
    background-color: purple !important;
    color: white !important;
    font-family: 'Josefin Sans' !important;
    font-weight: 'bold' !important;
    font-size: 18px !important;
}
.contact-button:hover{
    color: black !important;
}

.margin {
    margin-left: 10%;
}

.logo-title {
    font-family: 'Josefin Sans';
    color: purple;
    font-size: 30px;
    margin-left: 2vw;
  }

  .logo-title:hover {
      color:indigo;
  }

.link {
    z-index: 100;
}

@media only screen and (min-width: 699px) and (max-width: 1100px){
    .desktop {
        display: none !important
      }

      .dropdown-button{
          background-color:purple !important;
          color: white !important;
          font-size: 15px !important;
          left: 50% !important;
      }

}

@media only screen and (min-width: 360px) and (max-width: 699px){
    .desktop {
        display: none !important
      }
      .menu-container {
          flex-wrap: wrap;
      }
      .dropdown-button{
          background-color:purple !important;
          color: white !important;
          font-size: 15px !important;
          left: 5% !important;
      }
}
@media only screen and (min-width: 300px) and (max-width: 359px){
    .desktop {
    display: none !important
    }
    .dropdown-button{
        background-color:purple !important;
        color: white !important;
        font-size: 10px !important;
    }
}