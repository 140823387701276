@import "../index.css";

  .testimonial-person {
   margin-left: 20%;
    color: purple;
  }

  .testimonial-description {
      width: 50%;
      margin-left: 25%;
      font-size: 18px;
      line-height: 25px;
  }

  .testimonial-button-container{
    display: flex;
    flex-direction: row;
    margin-left: 35%;
    padding-top: 20px;
  }
  
  @media only screen and (min-width: 300px) and (max-width: 500px){
    .testimonial-description {
      width: 100%;
      margin-left: 0%;
    }
    .testimonial-button-container{
      margin-left: 10%;
    }
  }