@import "../index.css";

h1 {
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
}

 p {
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
 }

.background-image {
  z-index:-1;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }

  .cover-page-description {
    z-index: 100;
    font-weight: 800;
    font-size: 35px;
    line-height: 50px;
    width: 50%;
    margin-left: 25%;
    padding-top: 15%;
    margin-bottom: 5%;
  }

  .learn-more {
    z-index: 100;
    margin-top: 1vw !important;
    margin-bottom: 1vw !important;
    background-color: purple !important; 
    color: white !important;
    width: 150px !important;
    font-size: 15px !important;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
  }

  .learn-more:hover{
    color: black !important;
  }

  .purple-description{
    background-color: purple;
    color: white; 
    max-width: 100%;
    text-align: center; 
    font-size: 18px;
    padding: 4vw;
    padding-bottom: 20vh;
    margin-top: 5vh;
  }

  .amit-sitting-image {
    position: relative;
    width: 50%;
    height: auto;
    z-index: 100;
    padding-left: 6vw;
    padding-top: 4vw;
    padding-bottom: 5vw;
  }

  .amit-desk-image {
    position: relative;
    width: 50%;
    height: auto;
  }

  .amitFront-text{
    width: 45vw;
    padding-top: 6vw;
    font-size: 18px;
    line-height: 30px;
  }

  .amitDesk-text{
    margin-top: 30%;
    margin-left: 2%;
    font-size: 18px;
    line-height: 30px;
  }

  @media only screen and (min-width: 999px) and (max-width: 1250px){
    .amitDesk-text{
      margin-top: 22%;
    }
    .amit-desk-image {
      width: 60%
    }
    .amit-sitting-image {
      width: 60%;
    }
    .background-image {
      height: 140vh;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 999px){
    .amitDesk-text{
      margin-top: 10%;
    }
    .amit-desk-image {
      width: 50%
    }
    .amit-sitting-image {
      width: 50%;
    }
    .background-image {
      height: 160vh;
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 900px){
    .background-image {
      height: 180vh;
    }
    
  }
    @media only screen and (min-width: 300px) and (max-width: 900px){
    .amit-sitting-image {
      width: 94%;
      padding-left: 0vw;
      padding-top: 0vw;
      padding-bottom: 0vw;
    }
    .amit-desk-image {
      width: 94%;
    }
    .amitDesk-text{
      margin-top: 0%;
      width: 90%;
    }
    .amitFront-text{
      width: 90%;
      margin-left: 2%;
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 600px){
    .background-image {
      height: 94vh;
    }
    .cover-page-description {
      font-size: 28px;
      width: 100%;
      margin-left: 0%
  }
}
@media only screen and (min-width: 300px) and (max-width: 540px){
  .background-image {
    height: 130vh;
  }
}
